<template>
    <div class="attachments-container">
        <div class="attachments"  v-for="(i, index) in this.attachmentData.field_attachments" :key="index">
            <a :href="endpoint + i.attributes.uri.url" download target="_blank">
                <span v-if="attachmentData.relationships.field_attachments.data[index].meta.description">
                    {{ attachmentData.relationships.field_attachments.data[index].meta.description }}
                </span>
                <span v-else>
                    {{ i.attributes.filename }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'attachments',
        components: {},
        props: {
            attachmentData: Object
        },
        data: () => {
            return {}
        },
        computed: {
            endpoint (){
                return process.env.VUE_APP_ENDPOINT
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss"  >
    @import "../styles/variables.scss";
    @import "../styles/variables.scss";

    .attachments {
            max-width: calc(100% - 60px);
    overflow: hidden;
    }
</style>