<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="error 404" />
        <main>
            <section class="main-content">
                <div class="container">
                    <p>
                        The requested content does not exist.
                    </p>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
//import { fetchSinglePage } from '../libs/drupalClient'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    export default {
        name: 'not-found',
        components: {
            HeaderIntroImage
        },
        data: () => {
            return {
                pageData: {
                    attributes: {
                        title: "Not Found"
                    }
                },
                currentPageID: ""
            }
        },
        computed: {},
        methods: {},
        mounted() {}
    }
</script>

<style lang="scss" scoped>
</style>