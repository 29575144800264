//VUE MODULES
import Vue from 'vue'
import VueRouter from 'vue-router'

//VUE OBJECTS
import { fetchRoutes } from '../libs/drupalClient'
import store from '../store'

//COMPONENTS WITH CUSTOM ROUTES
import Homepage from '../views/Homepage'
import NotFound from '../views/NotFound'
import PrivacyNotice from '../views/PrivacyNotice'

let itemsToUse = [ VueRouter ]
itemsToUse.forEach((item)=>{
  Vue.use(item)
})

export function router() {
  return fetchRoutes().then(data => {

    //per estrarre e mostrare prima le routes con la lingua di default --> necessario per il giusto matching ordinato
    var fetchedRoutes = data.routes
      .filter(r => r.lang==data.langcode)
      .sort((a,b) => a.dynamic_group > b.dynamic_group ? 1 : -1)
    
    data.routes.filter(r => r.lang!=data.langcode).forEach(f => fetchedRoutes.push(f) )

    var ffroutes = []
    //imposta la lingua di default nello store dell'applicazione
    store.commit('SET_DEFAULT_LANG', data.langcode)
    
    //lista di tutte le possibili lingue --> per costruzione routes Homepage tradotta
    var languages = data.languages
    store.commit('SET_LANGUAGES', languages)
    ////console.log('All JCONFIG generated routes', fetchedRoutes)

    //helpers
    var pagine = []
    var path, comp
    //var lang = '/:lang'

    //COSTRUZIONE DELLA STRUTTURA DI ROUTING + ALIAS PER TRADUZIONI
    //per ogni route generata
    fetchedRoutes.forEach(f => {
      //carica il componente di Vue corrispondente
      comp = () => import( '../views/' + f.component_name + '.vue')
      //a meno che non si tratti della Homepage, caso speciale gestito alla fine
      if(f.component_name!='Homepage') {

        //se non è già stata analizzata e aggiunta la route
        if(!pagine.includes(f.component_name)) {
          //se si tratta di una route di pagine statiche
          if(f.dynamic_group=="") {
            path = f.path

          //altrimenti, se si tratta di un esempio di pagina di dettaglio
          } else {
            //aggiungi direttamente tutto il dynamic-group al routing
            path = f.dynamic_group
          }

          //analizza e aggiungi la pagina alla struttura, alias calcolati in seguito
          ffroutes.push({ nid: f.nid, path, component: comp, name: f.component_name, lang: f.lang, props: true, alias: [] })
          pagine.push(f.component_name)

        //se il tipo di pagina è già stato analizzato, si tratta di un'alias
        } else {
          //si trova la route già aggiunta che determina il gruppo di routing
          var as = ffroutes.find(a => a.name === f.component_name)

          //se si tratta di una pagina statica, si aggiunge il path specifico con la lingua per la traduzione
          // [/lingua/pagina]

          if(f.dynamic_group=="") {
            //as = ffroutes.find(a => a.nid === f.nid)
            as.alias.push(f.path)

          } else {
            //as = ffroutes.find(a => a.name === f.component_name)
            
            //se è una pagina di dettaglio, si aggiunge un alias uguale al dynamic-group ma con la lingua davanti
            // [/lingua/dynamic-group/:id/:title]
            if(!as.alias.includes(f.dynamic_group) && !as.alias.includes('/:lang' + f.dynamic_group)) // && f.dynamic_group!=as.path)
              as.alias.push('/:lang' + f.dynamic_group)
          }
        }
      }
    })

    var NFalias = []
    languages.forEach(l => {
      if(l!=data.langcode)
        NFalias.push('/' + l + '/not-found')
    })

    ffroutes.push({
      path: '/not-found', 
      lang: data.langcode,
      component: NotFound, 
      name: 'NotFound',
      alias: NFalias
    })


    ffroutes.find(r => r.path=="/privacy-notice").redirect= '/privacy-notice-science-exile-newsletter'

    ffroutes.push({
      path: '/privacy-notice-science-exile-newsletter',
      lang: data.langcode,
      component: PrivacyNotice,
      name: 'PrivacyNotice'
    })

    //infine si aggiunge dinamicamente la route della Home come caso speciale
    //calcolando gli alias per ogni lingua, che corrispondono semplicemente a [/:lang] per ognuna
    var Halias = []
    languages.forEach(l => {
      if(l!=data.langcode)
        Halias.push('/' + l)
    })

    ffroutes.push({
      path: '/', 
      lang: data.langcode,
      component: Homepage, 
      name: 'Homepage',
      alias: Halias
    })

    ////console.log('FINAL ROUTES', ffroutes)
    /*ffroutes = [{
      name: 'Homepage',
      path: '/',
      component: Homepage
    }]*/

    //console.log(ffroutes)

    return new VueRouter({
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
      mode: 'history',
      base: '',
      routes: ffroutes
    })
    
  })
}
