<template>
    <footer>
        <section class="loghi">
            <div class="container">
                <div>
                    <img src="../../assets/logo.svg" alt="">
                </div>
                <div>
                    <h4>
                        Organizing partners:
                    </h4>
                    <div>
                        <a class="logo-twas" href="https://twas.org/" target="_blank">
                            <img src="../../assets/logo-twas.png" alt="">
                        </a>

                        <a class="logo-iap" href="https://www.interacademies.org/" target="_blank">
                            <img src="../../assets/logo-iap.png" alt="">
                        </a>

                        <a class="logo-isc" href="https://council.science/" target="_blank">
                            <img src="../../assets/logo-isc.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="newsletter">
            <div class="container">
                <div>
                    <h3>
                        STAY UPDATED!
                    </h3>
                    <h2>
                        Subscribe <span>to the</span> Newsletter
                    </h2>
                    <img src="../../assets/plane-newsletter.svg" alt="">
                </div>
                <a class="button" target="_blank" href="https://forms.office.com/r/xqUGG0H61f">Subscribe</a>
            </div>
        </section>

        <section class="sitemap">
            <div class="container">
                <div class="footer-column" v-for="(c, index) in this.menuItems" :key="'footer-column--'+index">
                    <h3 v-if="c.label=='Contacts'" class="footer--title">{{ c.label }}</h3>
                    <div v-if="c.label=='Contacts'">
                        <router-link :to="c.url">{{ c.label }}</router-link>
                        <router-link id="hardcoded-link" to="/privacy-notice-science-exile-newsletter">Privacy Notice</router-link>
                    </div>

                    <h3 v-else class="footer--title">{{ c.label }}</h3>
                    <div class="footer-row" v-for="(r, index) in c.children" :key="'footer-row--'+index">
                        <router-link v-if="r.url!=''" :to="r.url">{{ r.label }}</router-link>
                        <span v-else>{{ r.label }}</span>
                    </div>
                </div>
            </div>
        </section>

    </footer>
</template>

<script>
    export default {
        name: 'the-footer',
        data: () => {
            return {
                menuItems: []
            }
        },
        methods: {
            async fetchMenuItems() {
                var menu = []
                var items = []
                var ch = []

                var l = this.lang != this.defaultLang ? ('/' + this.lang) : ''

                await fetch(`${process.env.VUE_APP_ENDPOINT}${l}/jconfig/menu/main`)
                    .then(res => res.json())
                    .then(json => {
                        items = json.menu //.sort((a, b) => { return a.weight > b.weight ? -1 : 1 })
                    })


                //console.log(items)
                //for each fetched route
                items.forEach(item => {
                    ch = []
                    //console.log(item)
                    //for each menu item with no parent 
                    //if the number of its children is 0
                    if (!item.has_children) {
                        //push it into the menu as a top-level route
                        menu.push({
                            label: item.title,
                            url: item.url,
                            children: []
                        })

                    } else {
                        item.subtree.forEach(child => ch.push({
                            label: child.title,
                            url: child.url,
                            children: []
                        }))

                        //then push them into the menu as a sub-level route (for that corresponding top-level menu)
                        menu.push({
                            label: item.title,
                            children: ch,
                            url: ""
                        })
                    }
                })

                //create the menu
                this.menuItems = menu
            },
        },
        computed: {},
        mounted() {
            this.fetchMenuItems();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../styles/variables.scss";

    #hardcoded-link { margin-top: 2rem; }

    .footer--title {
        color: $green;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .sitemap {
        background-color: #1C283E;
        padding: 2rem 0rem 0rem 0rem;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (min-width: 992px) {
                flex-direction: row;
            }

        }

        div {
            display: flex;
            flex-direction: column;
        }

        .footer-column {
            width: auto;
            margin: 0 0 2em 0;
        }

        h4 {
            color: #fff;
            font-weight: 700;
            margin: 1rem 0rem;
        }

        a {
            color: #C9CFD9;
            padding: 6px 0px;
            font-size: 0.875rem;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: #fff;
        }
    }

    .contacts {
        background-color: #121E33;
        padding: 1rem 0rem;
    }

    footer {

        .loghi {
            background: #fff;
            padding: 2rem 0rem;

            div>div:first-child {
                border-bottom: 1px solid rgb(219, 219, 219);
                display: flex;
                justify-content: center;
            }

            img {
                max-width: 300px;
                margin: 0px auto;
            }

            div>div:last-child {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    max-width: 260px;
                    max-height: 70px;
                    min-height: 50px;
                    margin: 0px auto;
                }
            }

            img {
                padding: 1rem 0rem;
            }

            h4 {
                font-size: 0.875rem;
                color: #666666;
                font-weight: 400;
                text-align: center;
                margin-top: 2rem;
            }
        }

        .newsletter {
            background-color: $green;
            padding: 2rem 0rem;
            position: relative;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            div {
                position: relative;
            }

            h3 {
                margin-bottom: 0.625rem;
                font-weight: 300;
                font-size: 14px;
                text-align: center;
            }

            h2 {
                margin-top: 0;
                line-height: 2rem;
                font-size: 1.75rem;
                color: #fff;
                text-align: center;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
            }

            button {
                margin-top: 2rem;
                background-color: $darkblue;
                margin: 2rem auto 0rem auto;
            }
        }

        .sitemap {
            padding: 2rem 0rem;
            background-color: $darkblue;
        }
    }


    @media (min-width:$container-breakpoints-md) {
        .sitemap .container {
            flex-wrap: wrap;
        }

        .sitemap .footer-column {
            width: 33%;
        }
    }

    @media (min-width: $container-breakpoints-lg) {
        footer .loghi img {
            height: 40px;
            padding: 0;
        }

        footer .loghi .container {
            align-items: flex-end;
        }

        footer .loghi div>div:first-child {
            justify-content: center;
            flex-direction: column;

            img {
                height: 60px;
            }
        }

        footer h4 {
            margin-bottom: 1rem;
        }
    }

    @media (min-width:$container-breakpoints-xl) {
        footer .loghi div>div:last-child img {
            max-width: none;
            max-height: none;
        }

        .logo-twas img {
            width: 214px !important;
            height: auto !important;
            max-width: none;
            max-height: none;
        }

        .logo-iap img {
            width: 126px !important;
            height: auto !important;
        }

        .logo-isc img {
            width: 189px !important;
            height: auto !important;
        }

        .button {
            margin: 1rem 0 1rem 5rem;
        }

        footer .loghi div>div:last-child {
            align-items: flex-start;
        }

        footer .loghi img {
            height: 60px;
            max-width: none;
        }

        footer .loghi {

            h4 {
                text-align: left;
            }

            h4 {
                margin-top: 0;
            }

            .container {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
            }

            div>div:first-child {
                border-bottom: 0px solid #dbdbdb;
            }

            >div>div:last-child {
                margin-left: 4rem;
            }

            div>div>div:last-child {
                flex-direction: row;

                img {
                    margin: 0 3rem 0 0rem;
                }
            }
        }

        footer {
            box-shadow: 0px 6px 28px 0px #00000014;

            .newsletter {
                padding: 3rem 0rem;

                .container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    h3 {
                        text-align: left;
                    }

                    h2 {
                        text-align: left;
                    }
                }

                button {
                    margin: 0rem 8rem;
                    width: auto;
                }
            }
        }

    }

    @media (min-width:$container-breakpoints-xxl) {
        .footer-column {
            margin: 0 0 2em 0;
        }

        .sitemap .footer-column {
            width: 12%;
        }
    }
</style>