import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lastPage: '',
    currentLang: 'en',
    defaultLang: '',
    languages: []
  },
  mutations: {
    SET_CURRENT_LANG: function(state, payload) {
      state.currentLang = payload;
    },
    SET_LAST_PAGE: function(state, payload) {
      state.lastPage = payload;
    },
    SET_DEFAULT_LANG: function(state, payload) {
      state.defaultLang = payload;
    },
    SET_LANGUAGES: function(state, payload) {
      state.languages = payload;
    }
  },
  actions: {}
});