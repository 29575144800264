<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
  import TheHeader from './components/layout-blocks/TheHeader'
  import TheFooter from './components/layout-blocks/TheFooter'

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  data: () => {
    return {
      metas: {}
    }
  },
  computed: {
    route() {
      return this.$route
    }
  },
  metaInfo() {
    return {
      title: /*this.metas.metadata ? (this.metas.metadata.title + '| Science in Exile') :*/ 'Science in Exile',
      meta: [
        /*{
          name: 'description',
          content: this.metas.metadata ? this.metas.metadata.description : ''
        },*/
        {
          property: 'og:title',
          content: this.metas.metadata ? this.metas.metadata.title : 'Science in Exile'
        },
        {
          property: 'og:site_name',
          content: 'Science in Exile'
        },
        {
          property: 'og:type',
          content: 'website'//this.metas.messages ? this.metas.messages.bundle : ''
        },
        /*{
          property: 'og:image',
          content: this.metas.metadata ? this.metas.metadata.image : (process.env.VUE_APP_ENDPOINT + '/social-image.jpg')
        },*/
        {
          name: 'robots',
          content: 'index,follow'
        }
      ] 
    }
  },
  methods: {
    refreshPage () {
      //evento chiamato al refresh della pagina o al reindirizzamento di una route
      //per evitare il cambio di lingua non voluto

      //aggiorna STORE e LOCALSTORAGE (come helper di sessione) simultaneamente
      localStorage.setItem('LS_LANG_KEY', this.$store.state.currentLang)
      this.$store.commit('SET_CURRENT_LANG', localStorage.getItem('LS_LANG_KEY'))
		},
    /*fetchMetas() {
      if(this.$route.fullPath=='/') {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/home')
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          json.metadata.title = "Homepage"
          this.metas = json
        })
      } else {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=' + this.$route.fullPath)
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          this.metas = json
        })
      }
      console.log(this.metas)
    }*/
  },
  mounted() {
    this.refreshPage()
    //this.fetchMetas()
  },
  watch: {
    route() {
      this.refreshPage()
      //this.fetchMetas()
    }
  }
}
</script>

<style lang="scss">
@import "style.scss";
footer{
  z-index: 1;
}
</style>
