<template>
    <header :class="['header-intro', marginbottom ? 'banner-margin-bottom' : '']">
            <div class="container">

                    <h2 v-html="this.menuparent"></h2>
                
                <h1>
                    {{ this.headerMetadata.attributes.title }}
                </h1>
                <slot></slot>
            </div>
            <img :src="bannerImage" alt="">
        </header>
</template>

<script>
export default {
    name: 'header-intro-page',
    props: {
        headerMetadata: Object,
        marginbottom: Boolean,
        menuparent: String,
        homepage: Boolean
    },
    computed:{
        bannerImage(){
            if(this.headerMetadata.field_page_banner)
                return process.env.VUE_APP_ENDPOINT + this.headerMetadata.field_page_banner.attributes.uri.url
            else if(this.homepage) {
                return require('../../assets/homepage-bg.jpg')
            } else {
                return require('../../assets/header-bg.jpg')
            }
        },
        route(){
            return this.$route.name
        }
    },
    mounted(){}
}
</script>

<style lang="scss">
</style>