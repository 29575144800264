<template>
    <div class="item closed" ref="news">
        <router-link :to="gotodetail" class="image">
            <img :src="newsimg" alt="">
        </router-link>
        <div class="content">
            <p class="date">{{ formatdate(newsData) }}</p>
            <router-link class="read-more" :to="gotodetail"><h2 class="tile-title">{{ newsData.attributes.title }}</h2></router-link>
            <p v-html="newsData.attributes.field_description_w_summary.summary">
            </p>
            <div class="fulltext" v-html="newsData.attributes.field_description_w_summary.value"></div>
            <!--<a class="read-more" @click="hide(index)">{{ 'Read More' }}</a>-->
            <router-link class="read-more" :to="gotodetail">Read more</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news',
    components: {},
    props: {
        newsData: Object,
        routesData: Array
    },
    data: () => {
        return {}
    },
    computed: {
        gotodetail() {
            if(this.routesData) {
                var f = this.routesData.filter(rr => this.newsData.attributes.drupal_internal__nid == rr.nid)
                //console.log(f[0])
                return f[0].path
            } else 
                return ""
        },
        newsimg() {
            if(this.newsData.field_image)
                return process.env.VUE_APP_ENDPOINT + this.newsData.field_image.attributes.uri.url
            else 
                return ''
        },
    },
    methods: {
         hide(n) {
            this.$refs.news[n].classList.toggle('closed')
        },
        formatdate(p) {
            return new Date(p.attributes.field_date).toLocaleString('en', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
    },
    mounted() {
        //console.log(this.newsData)
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
.closed {
    .fulltext { display: none; }
}

.date { margin: 0; }

.item {
    border-radius: 5px;
    margin-bottom: 2rem;
    background-color: #fff;

    .content { 
        padding: 2rem 1rem; 
        ::v-deep a:not(:has(h2)) { word-break: break-all; }
    }

    h2 {
        font-size: 1.375rem;
        line-height: 1.7rem;
    }

    p {
        max-width: none;
        &:not(.date) { margin-bottom: 0; }
    }

    .date { color: $orange; }

    .tile-title {
        font-size: 1.375rem;
        line-height: 1.7rem;
        color: #333333;
        transition: all 300ms ease;
        &:hover {
            opacity: .8;
            transition: all 300ms ease;
        }
    }

    .image {
        max-width: 100%;
        height: 250px;
        display: block;
        overflow: hidden;
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all 300ms ease;

            -o-object-fit: contain;
            position: absolute;
            object-fit: cover;
        }

        &:hover {
            img {
                transform: scale(1.1);
                transition: all 300ms ease;
            }
        }
    }
}

.highlights {
    .item {
        background-color: $darkblue;
        color: #fff;
        ::v-deep p { color: #fff; }
        .date { color: $orange; }
        h2 { color: #fff; }
    }
}

@media (min-width: 1024px) {
    .item {
            grid-column: span 1;
            .content { padding: 2rem 2rem; }
            h2 {
                font-size: 1.75rem;
                line-height: 2.1875rem;
            }
        }
}
</style>