<template>
    <div class="homepage">
        <HeaderIntroImage marginbottom homepage :headerMetadata="this.pageData">
            <h3 class="green-subtitle">{{ this.pageData.attributes.field_banner_text }}</h3>
        </HeaderIntroImage>
        <main v-if="this.pageData && this.pageData.attributes">
            <section>
                <div class="container center">
                    <p v-if="this.pageData.attributes.field_description_w_summary" 
                        v-html="this.pageData.attributes.field_description_w_summary.value"></p>
                    <router-link to="/issue" class="button btn">Read more</router-link>
                </div>
            </section>

            <section class="what-we-do" v-if="this.pageData.field_ref_ui_text && this.pageData.field_ref_ui_text.length">
                <div class="container">
                    <div class="col1">
                        <div>
                            <h2>{{ this.pageData.field_ref_ui_text[0].attributes.field_uitext_title.value }}</h2>
                            <p v-html="this.pageData.field_ref_ui_text[0].attributes.field_description_w_summary.value">
                            </p>
                        </div>
                    </div>

                    <div class="col2">
                        <img src="../assets/home.jpg" alt="">
                    </div>
                </div>
            </section>

            <section class="highlights">
                <div class="container" v-if="this.newsData">
                    <div class="title">
                        <h2>Highlights</h2>
                        <router-link to="/news">View all</router-link>
                    </div>

                    <div class="container container-items">
                        <NewsTile v-for="(n, index) in this.newsData" :key="'news'+index" :routesData="routesData"
                            :newsData="n" />
                    </div>
                </div>

                <div v-else>
                    <br>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import {
        fetchNodes,
        fetchRoutes,
        fetchSinglePage
    } from '../libs/drupalClient'
    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import NewsTile from '../components/NewsTile.vue'
    export default {
        name: 'home',
        components: {
            HeaderIntroImage,
            NewsTile
        },
        data: () => {
            return {
                pageData: {
                    attributes: {
                        title: "The Science in Exile initiative"
                    }
                },
                newsData: null,
                routesData: null,
                currentPageID: "c6a6007a-c985-4470-9cf3-c9c1011bc061"
            }
        },
        computed: {},
        methods: {
            newsimg(node) {
                var f = this.newsData.filter(p => p.attributes.drupal_internal__nid == node.attributes.drupal_internal__nid)
                if (f[0].field_image)
                    return process.env.VUE_APP_ENDPOINT + f[0].field_image.attributes.uri.url
                else
                    return ''
            },
            formatdate(p) {
                return new Date(p.attributes.field_date).toLocaleString('en', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
            },
            hide(n) {
                this.$refs.news[n].classList.toggle('closed')
            }
        },
        async mounted() {
            await fetchNodes('news', {
                include: ['field_image', 'field_attachment'],
                filters: [{
                    key: 'promote',
                    value: 1
                }]
            }).then(res => {
                this.newsData = res.sort((a, b) => {
                    return a.attributes.field_date > b.attributes.field_date ? -1 : 1
                })
            })

            await fetchRoutes().then(res => this.routesData = res.routes )

            await fetchSinglePage(this.currentPageID, {
                include: ['field_ref_ui_text']
            })
            .then(res => {
                this.pageData = res 
                //console.log(this.pageData)
            })
        }
    }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.highlights {
    .container .container { padding: 0; }
    .content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.highlights ::v-deep .item .content { padding: 2rem 0rem; }
.green-subtitle { color: $green; }
.green-title {
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: #83BA26;
}

.center {
    text-align: center;
    padding: 2em;

    p {
        font-size: 16px;
        line-height: 2em;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.what-we-do {
    background-color: $lightblue;

    .container {
        display: grid;
        grid-template-columns: 1fr;
        border-radius: 20px;
        position: relative;
        top: 4em;

        .col1, .col2 { grid-column: span 1; }

        .col1 {
            background: #fff;
            padding: 1em;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            h2 {
                font-weight: 500;
                font-size: 3rem;
            }
            div {
                position: relative;
                top: 50%;
                transform: translateY(-50%)
            }
        }

        .col2 {
            height: 300px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            overflow: hidden;
        }
    }
}

.highlights {
    background-color: $darkblue;
    padding-top: 8em;
    .title {
        display: flex;
        flex-direction: column;
    }
    h2 {
        font-weight: 500;
        font-size: 3rem;
        color: #fff;
    }
    a {
        color: $orange;
        margin-bottom: 1rem;
        margin-top: auto;
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em 3em;
}

@media (min-width: 768px) {
    .center p {
        font-size: 18px;
        width: 100%;
    }

    .highlights {
        .title {
            flex-direction: row;
            justify-content: space-between;
            align-content: flex-end;
        }
    }
}

@media (min-width: 1024px) {
    .center p { width: 75%; }

    .what-we-do {
        .container {
            grid-template-columns: 1fr 1fr;

            .col1 {
                border-radius: 0;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;

            }

            .col2 {
                height: 500px;
                border-radius: 0;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                overflow: hidden;
            }
        }
    }

    .what-we-do .container .col1 { padding: 1em 4em; }
    .container-items {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 4rem;
    }
    .item {
        grid-column: span 1;
        .content { padding: 2rem 2rem; }
        h2 {
            font-size: 1.75rem;
            line-height: 2.1875rem;
        }
    }
}

@media (min-width: 1300px) {
    .container-items { grid-template-columns: repeat(3, 1fr); }
}
</style>